import React, { useContext, useEffect, useRef, useState } from "react";
import { TableCell, TableRow, TableHead, IconButton, Typography } from "@material-ui/core";
import MUIDataTable, { TableHead as MuiTableHead } from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(() => ({
  colorCell: {
    backgroundColor: "#34558b",
    color: "#fff",
  },
  TableRow: {
    cursor: "pointer",
    paddingLeft: 0,
  },
  TableRowSub: {
    cursor: "default",
    paddingLeft: 0,
  },
  IconWrapper: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.10)",
      borderRadius: "50%",
    },
  },
}));

const Table = ({ dataTable, title }) => {
  const { NumberFormat } = useContext(GblContext);
  const classes = useStyles();

  const [open, setOpen] = useState({});

  const toggleRow = (name) => {
    const newRowState = { ...open };
    newRowState[name] = !newRowState[name];
    setOpen(newRowState);
  };

  let shouldGet = useRef(true);
  useEffect(() => {
    if (shouldGet.current) {
      shouldGet.current = false;
      const openSub = [];
      dataTable.map((el, i) => {
        if (el.children?.length > 0) {
          el.children.map((jItem) =>
            openSub.push({
              [`${jItem.Name}`]: true,
            })
          );
        }
        return el;
      });

      const result = openSub.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

      setOpen(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "Name",
      label: "Name",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value, data) => {
          return <b style={{ padding: 0 }}>{value}</b>;
        },
      },
    },
    {
      name: "ThisMonth",
      label: "This Month",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
    {
      name: "LastMonth",
      label: "Last Month",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
    {
      name: "MonthBudget",
      label: "Budget",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
    {
      name: "ThisYear",
      label: "This Year",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
    {
      name: "LastYear",
      label: "Last Year",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
    {
      name: "YearBudget",
      label: "Year Budget",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography align="center">
              <b>{NumberFormat(value)}</b>
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    print: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    pagination: false,
    setTableProps: () => ({
      size: "small",
    }),
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataTable[dataIndex]?.hasOwnProperty("children") === false) {
        return false;
      }

      return true;
    },
    rowsExpanded: dataTable.map((el, i) => i),
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.rowIndex;
      const subChild = dataTable[rowIndex].children;

      if (subChild && Array.isArray(subChild) && subChild.length > 0) {
        return subChild.map((subItem, index) => (
          <React.Fragment key={index}>
            <TableRow onClick={() => toggleRow(subItem.Name)}>
              <TableCell>
                {subItem.children && (
                  <IconButton onClick={() => toggleRow(subItem.Name)} size="small" style={{ padding: 0, margin: 0 }}>
                    {open[subItem.Name] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                )}
              </TableCell>
              <TableCell style={{ paddingLeft: 30 }}>
                {subItem.children ? <strong>{subItem.Name}</strong> : subItem.Name}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? (
                  <b>{NumberFormat(subItem.ThisMonth ?? 0)}</b>
                ) : (
                  NumberFormat(subItem.ThisMonth ?? 0)
                )}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? (
                  <b>{NumberFormat(subItem.LastMonth ?? 0)}</b>
                ) : (
                  NumberFormat(subItem.LastMonth ?? 0)
                )}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? (
                  <b>{NumberFormat(subItem.MonthBudget ?? 0)}</b>
                ) : (
                  NumberFormat(subItem.MonthBudget ?? 0)
                )}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? <b>{NumberFormat(subItem.ThisYear ?? 0)}</b> : NumberFormat(subItem.ThisYear ?? 0)}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? <b>{NumberFormat(subItem.LastYear ?? 0)}</b> : NumberFormat(subItem.LastYear ?? 0)}
              </TableCell>
              <TableCell align="center">
                {subItem.children ? (
                  <b>{NumberFormat(subItem.YearBudget ?? 0)}</b>
                ) : (
                  NumberFormat(subItem.YearBudget ?? 0)
                )}
              </TableCell>
            </TableRow>
            {open[subItem.Name] &&
              subItem.children &&
              subItem.children.map((item, childIndex) => (
                <TableRow key={childIndex} className={classes.TableRowSub}>
                  <TableCell />
                  <TableCell style={{ paddingLeft: 50 }}>
                    {item.IsTotal === true ? <strong>{item.Name}</strong> : item.Name}
                  </TableCell>
                  <TableCell align="center">{NumberFormat(item.ThisMonth ?? 0)}</TableCell>
                  <TableCell align="center">{NumberFormat(item.LastMonth ?? 0)}</TableCell>
                  <TableCell align="center">{NumberFormat(item.MonthBudget ?? 0)}</TableCell>
                  <TableCell align="center">{NumberFormat(item.ThisYear ?? 0)}</TableCell>
                  <TableCell align="center">{NumberFormat(item.LastYear ?? 0)}</TableCell>
                  <TableCell align="center">{NumberFormat(item.YearBudget ?? 0)}</TableCell>
                </TableRow>
              ))}
          </React.Fragment>
        ));
      }
      return null;
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: "#34558b",
          color: "#fff",
        },
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  const CustomTableHead = (props) => (
    <>
      <TableHead className={classes.colorCell}>
        <TableRow>
          <TableCell colSpan={2}></TableCell>
          
          <TableCell colSpan={3} align="center" className={classes.colorCell}>
            Current
          </TableCell>
          <TableCell colSpan={3} align="center" className={classes.colorCell}>
            Year to Date
          </TableCell>
        </TableRow>
      </TableHead>
      <MuiTableHead {...props} />
    </>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        title={title}
        data={dataTable}
        columns={columns}
        options={options}
        components={{ TableHead: CustomTableHead }}
      />
    </MuiThemeProvider>
  );
};

export default Table;
