import React, { useState, useEffect, useCallback } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { usePermissions, Loading, useTranslate } from "react-admin";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography } from "@material-ui/core";
import AccountPayable from "./AccountPayable";
import GeneralLedger from "./GeneralLedger";
import Asset from "./Asset";
import AccountReceivable from "./AccountReceivable";
import { getLicenseList } from "services/setting";
import AccessDenied from "layout/AccessDenied";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: theme.spacing(2, 1, 2),
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2),
    "&:focus": {
      opacity: 1,
    },
  },

  wrapper: {
    alignItems: "start",
  },
}))((props) => <Tab disableRipple {...props} />);

const Settings = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [licenseList, setLicenseList] = useStateWithCallbackLazy([]);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const CheckProductLicense = useCallback(async () => {
    const response = await getLicenseList();
    const list = response?.InterfacePermissionItems;

    if (list) {
      const activeLicense = list
        .filter(
          (i) => i.UniqueId.Id === "GL" || i.UniqueId.Id === "AP" || i.UniqueId.Id === "AST" || i.UniqueId.Id === "AR"
        )
        .filter((i) => i.CanUse);
      if (activeLicense?.length === 0) {
        setLicenseList(false);
      } else {
        activeLicense.forEach((element) => {
          element.UniqueId.Description = translate(`ra.module.${element.UniqueId.Description}`);
        });
        setLicenseList(activeLicense);
        setValue(list.findIndex((i) => i.UniqueId.Id === activeLicense[0].UniqueId.Id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;
    CheckProductLicense(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [CheckProductLicense]);

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {translate("ra.module.Setting")}
      </Typography>
      {
        {
          undefined: <Loading />,
          false: <AccessDenied />,
        }[licenseList]
      }
      <div className={classes.container}>
        <Tabs orientation="vertical" variant="fullWidth" value={value} onChange={handleChange} className={classes.tabs}>
          <CustomTab
            style={{
              display: licenseList?.find((i) => i.UniqueId.Id === "GL") === undefined ? "none" : "",
            }}
            label={translate("ra.module.General Ledger")}
            {...a11yProps(0)}
          />
          <CustomTab
            style={{
              display: licenseList?.find((i) => i.UniqueId.Id === "AP") === undefined ? "none" : "",
            }}
            label={translate("ra.module.Accounts Payable")}
            {...a11yProps(1)}
          />
          <CustomTab
            style={{
              display: licenseList?.find((i) => i.UniqueId.Id === "AR") === undefined ? "none" : "",
            }}
            label={translate("ra.module.Accounts Receivable")}
            {...a11yProps(2)}
          />
          <CustomTab
            style={{
              display: licenseList?.find((i) => i.UniqueId.Id === "AST") === undefined ? "none" : "",
            }}
            label={translate("ra.module.Asset")}
            {...a11yProps(3)}
          />
        </Tabs>

        {licenseList?.find((i) => i.UniqueId.Id === "GL") === undefined ? null : (
          <GeneralLedger value={value} index={0} permissions={permissions} licenseList={licenseList}>
            General Ledger
          </GeneralLedger>
        )}
        <AccountPayable value={value} index={1} permissions={permissions}>
          Account Payable
        </AccountPayable>
        <AccountReceivable value={value} index={2} permissions={permissions}>
          Account Receivable
        </AccountReceivable>
        <Asset value={value} index={3} permissions={permissions}>
          Asset
        </Asset>
      </div>
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(licenseList, 0, 2) : ""}</pre>
    </div>
  );
};

export default Settings;
