/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell, Tooltip } from "@material-ui/core";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
//import NavRight from "components/NavRightSide";
import PopupTable from "components/PopupTable";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { getAllocationJvDetail, createAllocationJvDetail, createJvFrDetail } from "services/generalLedger";
import { getActiveDimListByModuleName } from "services/dimension";
import Model from "models/allocationJv";
import SnackbarUtils from "utils/SnackbarUtils";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, formFields, formFieldsDetail, copyMode } = props;
  const redirect = useRedirect();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initData, setInitData] = useStateWithCallbackLazy({
    AJvhSeq: -1,
    Prefix: "",
    AllocateAmt: 0,
    AllocateUnit: 0,
    Description: "",
    Detail: [],
    DimHList: {
      Dim: [],
    },
    UserModified: localStorage.getItem("UserName"),
  });
  const { settingAll, NumberFormat, ToNumber, ToNumberFixedFormat, FixedSumValue } = useContext(GblContext);
  const { SettingSystem } = settingAll;

  const [initNewRow, setInitNewRow] = useState({
    index: -1,
    AJvhSeq: -1,
    AJvdSeq: -1,
    DeptCode: "",
    AccCode: "",
    Description: "",
    CurCode: SettingSystem.DefaultCurrencyCode,
    CurRate: SettingSystem.DefaultCurrencyRate,
    CrQty: 0,
    CrAmount: 0,
    CrBase: 0,
    DrQty: 0,
    DrAmount: 0,
    DrBase: 0,
    IsOverWrite: true,
    DimList: {
      Dim: [],
    },
  });
  const [originalData, setOriginalData] = useState();
  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, reset, getValues } = methods;

  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
  }

  let forceUpdate = useForceUpdate();

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    setTimeout(() => {
      setInitData(
        (state) => ({
          ...state,
          ...values,
          AllocateAmt: ToNumber(values.AllocateAmt),
          AllocateUnit: ToNumber(values.AllocateUnit),
        }),
        (nextState) => Save(nextState)
      );
    }, 500);
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Copy", disabled: true },
  ];

  // const fetchDimHListByModule = useCallback(async (mounted) => {
  //   const { Data } = await getActiveDimListByModuleName(10, 1, "GL-H");
  //   setInitData((state) => ({
  //     ...state,
  //     DimHList: {
  //       Dim: Data,
  //     },
  //   }));
  //   if (mounted) {
  //     setLoading(false);
  //   }
  // }, []);

  const fetchDimListByModule = useCallback(async (mounted) => {
    if (originalData) {
      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: originalData,
        },
      }));
    } else {
      const { Data } = await getActiveDimListByModuleName(10, 1, "GL-D");

      let original = _.cloneDeep(Data);
      setOriginalData(original);

      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: Data,
        },
      }));
    }

    if (mounted) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if (copyMode !== 0) {
      async function fetchJvDetail() {
        const response = await getAllocationJvDetail(localStorage.getItem("jvId"));
        if (response) {
          response.JvhSeq = Model.AJvhSeq;
          response.JvhNo = Model.JvhNo;
          response.Status = Model.Status;
          response.Detail.forEach((item) => {
            item.JvhSeq = -1;
            item.JvdSeq = -1;
          });
          switch (copyMode) {
            case 2:
              response.Detail.forEach((item) => {
                if (item.CrAmount === 0) {
                  item.CrQty = item.DrQty;
                  item.CrAmount = item.DrAmount;
                  item.CrBase = item.DrBase;
                  item.DrQty = 0;
                  item.DrAmount = 0;
                  item.DrBase = 0;
                } else {
                  item.DrQty = item.CrQty;
                  item.DrAmount = item.CrAmount;
                  item.DrBase = item.CrBase;
                  item.CrQty = 0;
                  item.CrAmount = 0;
                  item.CrBase = 0;
                }
              });
              break;
            case 3:
              response.Detail.forEach((item) => {
                item.CrQty = 0;
                item.CrAmount = 0;
                item.CrBase = 0;
                item.DrQty = 0;
                item.DrAmount = 0;
                item.DrBase = 0;
              });
              break;
            case 4:
              //Copy to template
              const { Code, InternalMessage, UserMessage } = await createJvFrDetail(response);
              if (Code === 0) {
                SnackbarUtils.success(UserMessage, function () {
                  redirect("show", "/glJvFr", InternalMessage, response);
                });
              }
              break;
            default:
              break;
          }
          setInitData(response);
          reset(response);
          localStorage.removeItem("jvCopyMode");
          localStorage.removeItem("jvId");
        }
        if (mounted) {
          setLoading(false);
        }
      }
      fetchJvDetail();
    }
    //fetchDimHListByModule(mounted);
    fetchDimListByModule(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const DisableUnit = () => {
    if (initData.Detail.length > 0) {
      document.getElementsByName("AllocateUnit")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
      document.getElementsByName("AllocateUnit")[0].parentNode.classList.add("Mui-disabled");
      document.getElementsByName("AllocateUnit")[0].disabled = true;
    } else {
      document.getElementsByName("AllocateUnit")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
      document.getElementsByName("AllocateUnit")[0].parentNode.classList.remove("Mui-disabled");
      document.getElementsByName("AllocateUnit")[0].disabled = false;
    }
  };

  const AddNewRow = () => {
    setEditIndex("");
    setShowAdd(true);
  };

  const UpdateRow = (value) => {
    setEditIndex(value);
    setShowAdd(true);
  };

  const CalculateAllocation = (arrDetail) => {
    const values = methods.watch();
    const allocateAmt = ToNumber(values.AllocateAmt);
    const sumDrBase = arrDetail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.DrBase);
      return NumberFormat(s ?? 0);
    }, 0);
    const sumCrBase = arrDetail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.CrBase);
      return NumberFormat(s ?? 0);
    }, 0);
    //DR
    if (ToNumber(sumDrBase) > allocateAmt) {
      let excessAmt = ToNumber(sumDrBase) - allocateAmt;
      let DIndex = arrDetail.findLastIndex((i) => i.DrQty > 0);
      let lastDebitDetail = arrDetail[DIndex];

      if (lastDebitDetail?.DrAmount) {
        lastDebitDetail.DrAmount = lastDebitDetail.DrAmount - excessAmt;
        lastDebitDetail.DrBase = lastDebitDetail.DrAmount * lastDebitDetail.CurRate;
      }
    }
    if (ToNumber(sumDrBase) < allocateAmt) {
      let excessAmt = allocateAmt - ToNumber(sumDrBase);
      let DIndex = arrDetail.findLastIndex((i) => i.DrQty > 0);
      let lastDebitDetail = arrDetail[DIndex];

      if (lastDebitDetail?.DrAmount) {
        lastDebitDetail.DrAmount = lastDebitDetail.DrAmount + excessAmt;
        lastDebitDetail.DrBase = lastDebitDetail.DrAmount * lastDebitDetail.CurRate;
      }
    }
    //CR
    if (ToNumber(sumCrBase) > allocateAmt) {
      let excessAmt = ToNumber(sumCrBase) - allocateAmt;
      let CIndex = arrDetail.findLastIndex((i) => i.CrQty > 0);
      let lastCreditDetail = arrDetail[CIndex];

      if (lastCreditDetail?.CrAmount) {
        lastCreditDetail.CrAmount = lastCreditDetail.CrAmount - excessAmt;
        lastCreditDetail.CrBase = lastCreditDetail.CrAmount * lastCreditDetail.CurRate;
      }
    }

    if (ToNumber(sumCrBase) < allocateAmt) {
      let excessAmt = allocateAmt - ToNumber(sumCrBase);
      let CIndex = arrDetail.findLastIndex((i) => i.CrQty > 0);
      let lastCreditDetail = arrDetail[CIndex];

      if (lastCreditDetail?.CrAmount) {
        lastCreditDetail.CrAmount = lastCreditDetail.CrAmount + excessAmt;
        lastCreditDetail.CrBase = lastCreditDetail.CrAmount * lastCreditDetail.CurRate;
      }
    }
  };

  const UpdateForm = (e) => {
    const values = methods.watch();
    if (initData.Detail.length !== 0) {
      const allocateAmt = ToNumber(values.AllocateAmt);
      const allocateUnit = ToNumber(values.AllocateUnit);
      initData.Detail.forEach((i, idx) => {
        i.index = idx;
        const drQty = ToNumber(i["DrQty"]);
        const crQty = ToNumber(i["CrQty"]);
        const curRate = ToNumber(i["CurRate"]);
        if (drQty > 0) {
          const drAmount = (allocateAmt / allocateUnit) * drQty;
          const drBase = drAmount * curRate;
          i.DrAmount = drAmount;
          i.DrBase = drBase;
        }
        if (crQty > 0) {
          const crAmount = (allocateAmt / allocateUnit) * crQty;
          const crBase = crAmount * curRate;
          i.CrAmount = crAmount;
          i.CrBase = crBase;
        }
      });

      if (e.target.name === "AllocateAmt") {
        CalculateAllocation(initData.Detail);
      }

      methods.setValue("AllocateAmt", allocateAmt);
      methods.setValue("AllocateUnit", allocateUnit);
    }
  };

  const SaveFromPopup = (arr, row) => {
    const allocateUnit = ToNumber(methods.getValues("AllocateUnit"));
    const index = arr.Detail.findIndex((el) => el.index === editIndex);

    if (editIndex !== "") {
      //update
      arr.Detail[index] = row;
      const sumDrQty = arr.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.DrQty);
        return s ?? 0;
      }, 0);

      const sumCrQty = arr.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.CrQty);
        return s ?? 0;
      }, 0);

      if (allocateUnit === sumDrQty && allocateUnit === sumCrQty) {
        CalculateAllocation(arr.Detail);
      }
      setInitData(arr);
      setShowAdd(false);
    } else {
      //create
      if (arr.Detail) {
        row.index = arr.Detail.length;
        arr.Detail = [...arr.Detail, row];
        const sumDrQty = arr.Detail.reduce((accu, item) => {
          const s = ToNumber(accu) + ToNumber(item.DrQty);
          return s ?? 0;
        }, 0);

        const sumCrQty = arr.Detail.reduce((accu, item) => {
          const s = ToNumber(accu) + ToNumber(item.CrQty);
          return s ?? 0;
        }, 0);

        if (allocateUnit === sumDrQty && allocateUnit === sumCrQty) {
          CalculateAllocation(arr.Detail);
        }
        setInitData(arr);
        setShowAdd(false);
      }
    }
    fetchDimListByModule(true);
    DisableUnit();
  };

  const CancelFromPopup = () => {
    setShowAdd(false);
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => UpdateRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
    },
    {
      name: "AccCode",
      label: "Account #",
    },
    {
      name: "AccDesc",
      label: "Account Name",
    },
    {
      name: "Description",
      label: "Comment",
      options: {
        display: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency",
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "DrQty",
      label: "Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          if (value > 0) {
            return NumberFormat(value);
          } else {
            return NumberFormat(initData.Detail[tableMeta.rowIndex].CrQty);
          }
        },
      },
    },
    {
      name: "DrAmount",
      label: "Dr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmount",
      label: "Cr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrBase",
      label: "Dr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrBase",
      label: "Cr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "580px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumDrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumDrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {/* Add TableCellEmpty For Summary Space */}
            <TableCell className={footerClasses} />
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.name === "DrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrAmt}
                    </TableCell>
                  );
                } else if (col.name === "CrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrAmt}
                    </TableCell>
                  );
                } else if (col.name === "DrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrBase}
                    </TableCell>
                  );
                } else if (col.name === "CrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrBase}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) initData.Detail.splice(removeArray[i], 1);
      DisableUnit();
    },
  };

  const CheckFieldAllocate = () => {
    let allocateAmt = getValues("AllocateAmt") ? ToNumber(getValues("AllocateAmt")) : 0;
    let allocateUnit = getValues("AllocateUnit") ? ToNumber(getValues("AllocateUnit")) : 0;
    if (allocateAmt > 0 && allocateUnit > 0) {
      // setInitNewRow(
      //   (state) => ({
      //     ...state,
      //     CrQty: 0,
      //     CrAmount: allocateAmt/allocateUnit,
      //     CrBase: allocateAmt/allocateUnit,
      //     DrQty: 0,
      //     DrAmount: allocateAmt/allocateUnit,
      //     DrBase: allocateAmt/allocateUnit,
      //   }),
      //   () => AddNewRow()
      // );
      AddNewRow();
    } else {
      if (allocateAmt < 1) {
        SnackbarUtils.warning(translate("ra.gl.allocateAmtWarnningZero"));
      }
      if (allocateUnit < 1) {
        SnackbarUtils.warning(translate("ra.gl.allocateUnitWarnningZero"));
      }
    }
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={1}>
              <IconButton size={"small"} onClick={CheckFieldAllocate} style={{ marginLeft: 6 }}>
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell align="right" colSpan={10} />
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const CheckDrCr = (field, m, data) => {
    let CurRate = ToNumber(data["CurRate"]);
    let allocateAmt = getValues("AllocateAmt") ? ToNumber(getValues("AllocateAmt")) : 0;
    let allocateUnit = getValues("AllocateUnit") ? ToNumber(getValues("AllocateUnit")) : 0;
    let drQty = ToNumber(data["DrQty"]);
    let crQty = ToNumber(data["CrQty"]);
    let drAmount = (allocateAmt / allocateUnit) * drQty;
    let crAmount = (allocateAmt / allocateUnit) * crQty;

    const sumDrQty = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.DrQty);
      return s ?? 0;
    }, 0);

    const sumDrBase = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.DrBase);
      return NumberFormat(s ?? 0);
    }, 0);

    const sumCrQty = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.CrQty);
      return s ?? 0;
    }, 0);

    const sumCrBase = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.CrBase);
      return NumberFormat(s ?? 0);
    }, 0);

    if (sumDrQty === allocateUnit - 1) {
      drAmount = allocateAmt - ToNumber(sumDrBase);
    }

    if (sumCrQty === allocateUnit - 1) {
      crAmount = allocateAmt - ToNumber(sumCrBase);
    }

    if (field === "DrQty" && data[field] !== 0) {
      m.setValue("DrAmount", drAmount);
      m.setValue("DrBase", drAmount * CurRate);
      m.setValue("CrQty", 0);
      m.setValue("CrAmount", 0);
      m.setValue("CrBase", 0);
    }
    if (field === "CrQty" && data[field] !== 0) {
      m.setValue("CrAmount", crAmount);
      m.setValue("CrBase", crAmount * CurRate);
      m.setValue("DrQty", 0);
      m.setValue("DrAmount", 0);
      m.setValue("DrBase", 0);
    }
  };

  const CheckValueBeforePost = (values) => {
    if (values.Detail.length > 0) {
      let sumDrBase = values.Detail.reduce((total, cur) => {
        return FixedSumValue(total + ToNumber(cur.DrBase));
      }, 0);
      let sumCrBase = values.Detail.reduce((total, cur) => {
        return FixedSumValue(total + ToNumber(cur.CrBase));
      }, 0);

      let sumDrQty = values.Detail.reduce((total, cur) => {
        return FixedSumValue(total + ToNumber(cur.DrQty));
      }, 0);

      let sumCrQty = values.Detail.reduce((total, cur) => {
        return FixedSumValue(total + ToNumber(cur.CrQty));
      }, 0);

      if (sumDrBase !== sumCrBase) {
        SnackbarUtils.warning(translate("ra.info.Unbalanced"));
        setBtnLoading(false);
        return false;
      }

      if (sumDrBase !== ToNumber(values.AllocateAmt)) {
        SnackbarUtils.warning(`Total amount must equal to Amount to Allocated ${values.AllocateAmt}`);
        setBtnLoading(false);
        return false;
      }

      if ((sumDrQty + sumCrQty) / 2 !== ToNumber(values.AllocateUnit)) {
        SnackbarUtils.warning(`Total Qty Dr or Cr must be equal with Allocated Unit ${values.AllocateUnit}`);
        setBtnLoading(false);
        return false;
      }

      let foundZeroItem = values.Detail.filter(
        (item) => ToNumber(item.DrAmount) === 0 && ToNumber(item.CrAmount) === 0
      );

      if (foundZeroItem.length > 0) {
        let msg = `Amount cannot be zero, would you like to remove transaction(s) ? 
        Note: All transactions with no amounts will be deleted.`;
        SnackbarUtils.confirm(
          msg,
          function () {
            var indexList = foundZeroItem.map((i) => i.index);
            for (var i = indexList.length - 1; i >= 0; i--) {
              values.Detail.splice(indexList[i], 1);
            }
            forceUpdate();
            Save(values);
          },
          function () {
            setBtnLoading(false);
          }
        );
      } else {
        return true;
      }
    } else {
      SnackbarUtils.info(translate("ra.info.notransaction"));
      setBtnLoading(false);
      return false;
    }
  };

  const Save = async (values) => {
    var isPass = CheckValueBeforePost(values);

    values.Detail.map((item) => {
      item.DrAmount = ToNumberFixedFormat(item.DrAmount);
      item.DrBase = ToNumberFixedFormat(item.DrBase);
      item.CrAmount = ToNumberFixedFormat(item.CrAmount);
      item.CrBase = ToNumberFixedFormat(item.CrBase);
      return item;
    });

    if (isPass) {
      SaveFnc(values);
    } else {
      setLoading(false);
    }
  };

  const SaveFnc = async (values) => {
    setBtnLoading(false);
    const { Code, InternalMessage, UserMessage } = await createAllocationJvDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect(`${InternalMessage}/show`);
      });
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  // const ShowDim = (values) => {
  //   if (!values) {
  //     setDataDim(initData.DimHList.Dim);
  //     setOpenDim(!openDim);
  //   } else {
  //     setDataDim(values);
  //     setOpenDim(!openDim);
  //   }
  // };

  const CancelFnc = () => {
    localStorage.removeItem("jvCopyMode");
    localStorage.removeItem("jvId");
    localStorage.removeItem("templateId");
    redirect("list", basePath);
  };

  if (loading) return <Loading />;
  if (!initData) return null;

  return (
    <div
    // className={clsx(classes.drawer, {
    //   [classes.drawerOpen]: openDim,
    //   [classes.drawerClose]: !openDim,
    // })}
    >
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper style={{ padding: 16 }}>
          <BoxHeader header={"Allocation Voucher"} source={initData.JvhSource} status={initData.Status} />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
            <Grid item xs={12}>
              <MuiTranslateTable
                data={initData.Detail}
                columns={columns}
                options={options}
                components={{
                  TableHead: CustomHeader,
                }}
              />
              {showAdd && (
                <PopupTable
                  initialValues={editIndex !== "" ? initData.Detail.find((i) => i.index === editIndex) : initNewRow}
                  formFields={formFieldsDetail}
                  update={CheckDrCr}
                  open={showAdd}
                  save={(row) => SaveFromPopup(initData, row)}
                  cancel={CancelFromPopup}
                  showDim
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
