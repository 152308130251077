/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import clsx from "clsx";
import { useTranslate } from "react-admin";
import { useTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { getArFolioUnpaidList } from "services/accountReceivable";
import MeterialtableTranslate from "components/MeterialtableTranslate";
import { Dialog, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import NumberFormatInput from "components/NumberFormatInput";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  numberBox: {
    textAlign: "right",
    fontSize: 14,
    //paddingRight: 4,
    margin: 0,
    width: 120,
  },
  numberBoxError: {
    border: "2px solid red !important",
    "&:hover, &:focus": {
      outline: "none",
      outlineOffset: 0,
      border: "2px solid red !important",
    },
  },
}));

const DialogFolio = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const { settingAll, DateToString, ToNumber, NumberFormat, FindTaxAmount } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const { onClose, open, ArNo, Rate, initNewRow, currentDetail, rowLength, lookupList } = props;
  const [Data, setData] = useState();

  const fetchSearchList = async () => {
    const Data = await getArFolioUnpaidList(ArNo);
    if (Data) {
      if (currentDetail.length > 0) {
        currentDetail.forEach((element) => {
          let isSelectedIdx = Data.findIndex((u) => u.PfmSeq === element.PfmSeq);
          if (isSelectedIdx !== -1) {
            Data[isSelectedIdx].tableData = { checked: true };

            if (element.Paid > 0) {
              Data[isSelectedIdx].Paid = element.Paid;
            } else {
              Data[isSelectedIdx].Paid = Data[isSelectedIdx].TotalAmt - Data[isSelectedIdx].Unpaid;
              Data[isSelectedIdx].Unpaid = Data[isSelectedIdx].Unpaid + Data[isSelectedIdx].Paid;
            }
          }
        });
      }
      setData(Data);
    } else {
      setData(currentDetail);
    }
  };

  React.useEffect(() => {
    if (open) {
      fetchSearchList();
    }
  }, [open]);

  const handleFocus = (e) => {
    e.target.select();
    setTimeout(function () {
      e.target.setSelectionRange(0, e.target.value.length);
    }, 0);
  };

  const columns = [
    {
      title: "",
      field: "PfmSeq",
      sorting: true,
      hidden: true,
    },
    {
      title: "Folio No.",
      field: "FolioNo",
      sorting: true,
    },
    {
      title: "Guest No.",
      field: "GuestNo",
      sorting: true,
    },
    {
      title: "Date",
      field: "FolioDate",
      sorting: true,
      render: (rowData) => {
        let v = rowData.FolioDate ? new Date(rowData.FolioDate) : "";
        return (
          <div style={{ width: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {DateToString(v)}
          </div>
        );
      },
    },
    {
      title: "Description",
      field: "Description",
      sorting: true,
      render: (rowData) => (
        <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {rowData.Description}
        </div>
      ),
    },
    {
      title: "Unit",
      field: "Unit",
      sorting: true,
    },
    { title: "Qty", field: "Qty", sorting: true, render: (rowData) => NumberFormat(rowData.Qty) },
    { title: "Amount", field: "TotalAmt", sorting: true, render: (rowData) => NumberFormat(rowData.TotalAmt) },
    {
      title: "Unpaid",
      field: "Unpaid",
      sorting: true,
      render: (rowData) => {
        const paidAmt = ToNumber(rowData.Paid);
        const calPaid = rowData.Unpaid - paidAmt;
        if (calPaid < 0 && rowData.Unpaid > 0) {
          rowData.Unpaid = ToNumber(rowData.Unpaid);
          return NumberFormat(rowData.Unpaid);
        } else if (calPaid <= 0 && rowData.Unpaid <= 0) {
          //สำหรับ invoice ติดลบ เช็ค < ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidAmt;
          if (rowData.NewUnPaid < rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        } else {
          //สำหรับ invoice ค่าบวก เช็ค > ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidAmt;
          if (rowData.NewUnPaid > rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        }
      },
    },
    {
      title: "Paid",
      field: "Paid",
      type: "numeric",
      sorting: true,
      render: (rowData) => {
        const checkValue = () => {
          if (rowData.Paid === 0) {
            return true;
          } else if (rowData.Paid < 0 && rowData.Unpaid > 0) {
            return true;
          } else if (rowData.Paid > 0 && rowData.Unpaid < 0) {
            return true;
          } else if (rowData.Paid > rowData.Unpaid && rowData.Unpaid > 0) {
            return true;
          } else if (rowData.Paid < rowData.Unpaid && rowData.Unpaid < 0) {
            return true;
          } else {
            return false;
          }
        };
        if (rowData.tableData.checked) {
          if (!settingAll.SettingAr.AllowEditFolioInInvoice) {
            return <div>{NumberFormat(rowData.Paid)}</div>;
          }
          return (
            <NumberFormatInput
              className={clsx(classes.numberBox, {
                [classes.numberBoxError]: checkValue(),
              })}
              value={rowData.Paid}
              onChange={(e) => {
                const value = NumberFormat(ToNumber(e.target.value));
                Data[rowData.tableData.id].Paid = ToNumber(value);
                setData([...Data]);
                return value;
              }}
              onFocus={handleFocus}
              decimal={SettingSystem.CurrencyBaseDecimal}
              decimalSep={SettingSystem.DecimalSeparator}
              thousandSep={SettingSystem.ThousandSeparator}
            />
          );
        } else {
          return <div>{NumberFormat(rowData.Paid)}</div>;
        }
      },
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: true,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    let error = false;
    let arrSelected = Data.filter((item) => item.tableData.checked === true);
    let newInvDetail = [];
    arrSelected.forEach((item, idx) => {
      const iPaid = ToNumber(item.Paid);
      const iUnpaid = ToNumber(item.NewUnPaid ? item.NewUnPaid : item.Unpaid);
      if (iPaid === 0) {
        item.Selected = false;
      } else {
        item.Selected = true;
        if (iPaid > iUnpaid && iPaid > 0) {
          SnackbarUtils.error(translate("ra.ar.folioAmountWarnning1"));
          error = true;
          return;
        } else if (iPaid > 0 && iUnpaid < 0) {
          SnackbarUtils.error(translate("ra.ar.folioAmountWarnning1"));
          error = true;
          return;
        } else if (iPaid > iUnpaid && iUnpaid > 0) {
          SnackbarUtils.error(translate("ra.ar.folioAmountWarnning1"));
          error = true;
          return;
        } else if (iPaid < iUnpaid && iUnpaid < 0) {
          SnackbarUtils.error(translate("ra.ar.folioAmountWarnning1"));
          error = true;
          return;
        }
      }

      item["NetAmt"] = iPaid;

      let TaxAmt1 = FindTaxAmount(item["TaxType1"], item["TaxRate1"], item["NetAmt"]);
      let TaxAmt2 = FindTaxAmount(item["TaxType2"], item["TaxRate2"], item["NetAmt"]);

      if (item["TaxType1"] === "Include") {
        item["NetAmt"] = item["NetAmt"] - TaxAmt1;
      }
      if (item["TaxType2"] === "Include") {
        item["NetAmt"] = item["NetAmt"] - TaxAmt2;
      }
      let deptDesc = lookupList["departmentList"].find((i) => i.DeptCode === item.DeptCode)?.Description;
      let crDesc = lookupList["accountCodeList"].find((i) => i.AccCode === item.CrAcc)?.Description;
      let drDesc = lookupList["accountCodeList"].find((i) => i.AccCode === item.DrAcc)?.Description;
      let taxAcc1Desc = lookupList["accountCodeList"].find((i) => i.AccCode === item.TaxAcc1)?.Description;

      newInvDetail.push({
        ...initNewRow,
        GroupNo: idx + rowLength,
        index: idx + rowLength - 1,
        InvdDesc: item.Description,
        InvdRemark: item.Remark,
        DeptCode: item.DeptCode,
        DeptDesc: deptDesc,
        CrAcc: item.CrAcc,
        CrAccDesc: crDesc,
        DrAcc: item.DrAcc,
        DrAccDesc: drDesc,
        Qty: item.Qty,
        Unit: item.Unit,
        Price: item.Paid,
        NetAmt: item.NetAmt,
        TotalAmt: item.NetAmt + TaxAmt1 + TaxAmt2,
        TaxAcc1: item.TaxAcc1,
        TaxAcc1Desc: taxAcc1Desc,
        TaxType1: item.TaxType1,
        TaxRate1: item.TaxRate1,
        TaxAmt1: TaxAmt1,
        TaxAcc2: item.TaxAcc2,
        TaxType2: item.TaxType2,
        TaxRate2: item.TaxRate2,
        TaxAmt2: TaxAmt2,
        InvdDate: item.FolioDate,
        PfmSeq: item.PfmSeq,
        NetBaseAmt: NumberFormat(item.NetAmt * Rate),
        TaxBaseAmt1: NumberFormat(TaxAmt1 * Rate),
        TaxBaseAmt2: NumberFormat(TaxAmt2 * Rate),
        TotalBaseAmt: item.NetAmt * Rate + TaxAmt1 * Rate + TaxAmt2 * Rate,
        Paid: iPaid,
        Unpaid: item.Paid,
        Selected: true,
      });
    });
    if (!error) {
      onClose(newInvDetail);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        A/R Folio List
      </DialogTitle>
      <DialogContent dividers>
        <MeterialtableTranslate
          title={""}
          data={Data}
          columns={columns}
          options={options}
          onSelectionChange={(rows, selectedRows) => {
            //Select or UnSelect 1 Item
            if (selectedRows) {
              if (selectedRows.tableData.checked) {
                selectedRows["Selected"] = true;
                selectedRows.Paid = ToNumber(selectedRows.Unpaid);
              } else {
                selectedRows.Paid = 0;
                selectedRows["Selected"] = false;
              }
              Data[selectedRows.tableData.id] = selectedRows;
              setData([...Data]);
            }
            //Select All
            if (rows.length > 0 && !selectedRows) {
              Data.forEach((item) => {
                item["Selected"] = true;
                item.Paid = item.Unpaid;
              });
              setData([...Data]);
            }
            //UnSelect All
            if (rows.length === 0 && !selectedRows) {
              Data.forEach((item) => {
                item["Selected"] = false;
                item.Paid = 0;
              });
              setData([...Data]);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" type="submit" onClick={handleOk}>
          {translate("ra.actionMenu.OK")}
        </Button>
        <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
          {translate("ra.action.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFolio;
