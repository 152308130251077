import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  MuiAutosuggest,
  SelectInForm,
  DateInForm,
  DescInForm,
  NumberFormatInForm,
} from "components/Form";

import { getAstCategory, getAstDepartment, getAstLocation } from "services/asset";

import { getApVendorList } from "services/accountPayable";

import { getAccountCodeList, getDepartmentList, getUnitList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { AssetLifeOptions } from "utils/options";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
  content: {
    padding: 4,
  },
}));

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingAsset, SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  const newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeListCA: [],
    accountCodeListAA: [],
    accountCodeListDA: [],
    departmentList: [],
    currencyList: [],
    vendorList: [],
    unitList: [],
    astCategoryList: [],
    astDepartmentList: [],
    astLocationList: [],
  });

  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Asset");
    setLookupList((state) => ({
      ...state,
      accountCodeListCA: Data,
      accountCodeListAA: Data,
      accountCodeListDA: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "ASSET",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);
  const fetchUnitLookup = useCallback(async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  }, []);
  const fetchVendorLookup = useCallback(async () => {
    const { Data } = await getApVendorList();
    setLookupList((state) => ({
      ...state,
      vendorList: Data,
    }));
  }, []);

  const fetchAstCategoryLookup = useCallback(async () => {
    const { Data } = await getAstCategory();
    setLookupList((state) => ({
      ...state,
      astCategoryList: Data,
    }));
  }, []);

  const fetchAstDepartmentLookup = useCallback(async () => {
    const { Data } = await getAstDepartment();
    setLookupList((state) => ({
      ...state,
      astDepartmentList: Data,
    }));
  }, []);

  const fetchAstLocationLookup = useCallback(async () => {
    const { Data } = await getAstLocation();
    setLookupList((state) => ({
      ...state,
      astLocationList: Data,
    }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchCurrencyLookup();
      fetchUnitLookup();
      fetchVendorLookup();
      fetchAstCategoryLookup();
      fetchAstDepartmentLookup();
      fetchAstLocationLookup();
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchCurrencyLookup,
    fetchUnitLookup,
    fetchVendorLookup,
    fetchAstCategoryLookup,
    fetchAstDepartmentLookup,
    fetchAstLocationLookup,
  ]);

  const formFieldsEdit = [
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={`${SettingAsset.IdFormat === "Manual" ? "*" : ""} ${translate("ra.field.Asset No.")}`}
          name="Id"
          variant="outlined"
          margin="dense"
          disabled={!addMode ? true : SettingAsset.IdFormat !== "Manual"}
          rule={{
            required: {
              value: SettingAsset.IdFormat === "Manual",
              message: "* Required",
            },
            maxLength: {
              value: SettingAsset.IdFormat === "Manual" ? 16 : 24,
              message: SettingAsset.IdFormat === "Manual" ? "maximum length is 16" : "maximum length is 24",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: (
        <TextFieldInForm
          label=""
          name="No"
          variant="outlined"
          margin="dense"
          disabled={!addMode}
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.Asset Name")}`}
          name="Name"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.BarCode")}
          name="Barcode"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximum length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={`*${translate("ra.field.Input Date")}`}
          name="InputDate"
          minDate={new Date(newClosePeriodAsset)}
          minDateMessage={"Date must be more than close period"}
          //for custom advance update field
          useFncUpdate={true}
          fncUpdate={async (value, methods) => {
            const p = {
              Module: "ASSET",
              CurrDate: ToMySqlDate(value),
            };
            const newCurrList = await getLookupCurrency(p);
            const currentCurCode = methods.watch("CurCode");
            const currentCurRate = methods.watch("CurRate");
            const newCurrRate = newCurrList.find((i) => i.CurrCode === currentCurCode).CurrRate;
            if (newCurrRate !== ToNumber(currentCurRate)) {
              SnackbarUtils.confirm(translate("ra.question.currencyWarning"), function () {
                methods.setValue("CurRate", newCurrRate);
              });
            }
            setLookupList((state) => ({
              ...state,
              currencyList: newCurrList,
            }));
          }}
          required
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={`*${translate("ra.field.Acquire Date")}`}
          name="AcquireDate"
          //minDate={new Date(newClosePeriodAsset)}
          maxDate={"InputDate"}
          customMaxDate={"InputDate"}
          maxDateMessage={"Date must be more than close period"}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Category")}`}
          name="CategoryCode"
          optKey="CateCode"
          optDesc="Description"
          options={lookupList["astCategoryList"]}
          updateOtherField={[{ key: "AstCateDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "AstCateDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AstCateDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Department")}`}
          name="DepartmentCode"
          optKey="DepartmentCode"
          optDesc="Description"
          options={lookupList["astDepartmentList"]}
          updateOtherField={[{ key: "AstDeptDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "AstDeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AstDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Invoice No.")}
          name="InvoiceNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Transfer Date")}
          name="TransferDate"
          minDate={new Date(newClosePeriodAsset)}
          minDateMessage={"Date must be more than close period"}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Location")}`}
          name="LocationCode"
          optKey="LocCode"
          optDesc="Description"
          options={lookupList["astLocationList"]}
          updateOtherField={[{ key: "AstLocDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "AstLocDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AstLocDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },

    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Vendor")}
          name="VnCode"
          optKey="VnCode"
          optDesc="VnName"
          options={lookupList["vendorList"]}
          updateOtherField={[{ key: "VnName", optKey: "VnName" }]}
        />
      ),
    },
    {
      size: 2,
      name: "VnName",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnName"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },

    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Serial No.")}
          name="SerialNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximum length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Specification")}
          name="Spec"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Remark")}
          name="Remark"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsRegister = [
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Currency")}`}
          name="CurCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Rate")}`}
          name="CurRate"
          rule={{
            min: {
              value: 0.000001,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Amount/Unit")}`}
          name="Amount"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            min: {
              value: true,
              message: "Amount must be more than zero",
            },
          }}
          decimal={SettingSystem.CostPerUnitBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Base Amt.")}
          name="BaseAmount"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Unit")}`}
          name="UnitCode"
          optKey="UnitCode"
          optDesc="Description"
          options={lookupList["unitList"]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Qty")}`}
          name="Qty"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.BaseQtyDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Salvage/Unit")}
          name="Salvage"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total Salvage")}
          name="TotalSalvage"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 2,
      field: (
        <SelectInForm label={translate("ra.field.Asset Life")} name="LifeType" options={AssetLifeOptions} disabled />
      ),
    },
    {
      size: 1,
      field: (
        <NumberFormatInForm
          label=""
          name="Life"
          decimal={0}
          rule={{
            min: {
              value: 1,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Init Accu Depre")}
          name="InitAccu"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Net Book Value")}
          name="RemainNet"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Total Value")}`}
          name="TotalCost"
          variant="outlined"
          margin="dense"
          readOnly
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const formFieldsRemainValue = [
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Qty")}
          name="RemainInfo.Qty"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm label={translate("ra.field.Life (Days)")} name="RemainInfo.Life" readOnly decimal={0} />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total Value")}
          name="RemainInfo.TotalValue"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Accu Depre.")}
          name="RemainInfo.AccuDepre"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Net Book Value")}
          name="RemainInfo.NetBookValue"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const formFieldsAccount = [
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Asset Dept.")}`}
          name="CostDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "CostDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListCA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("CostAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("CostAccCode", "");
                methods.setValue("CostAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListCA: oldAccList,
              }));
            }
          }}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "CostDeptDesc",
      field: (
        <DescInForm
          name="CostDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Accu Dept.")}`}
          name="AccuDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "AccuDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListAA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("AccuAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("AccuAccCode", "");
                methods.setValue("AccuAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListAA: oldAccList,
              }));
            }
          }}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "AccuDeptDesc",
      field: (
        <DescInForm
          name="AccuDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Depre Dept.")}`}
          name="DepreDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DepreDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListDA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("DepreAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("DepreAccCode", "");
                methods.setValue("DepreAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListDA: oldAccList,
              }));
            }
          }}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "DepreDeptDesc",
      field: (
        <DescInForm
          name="DepreDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Asset Acc.")}`}
          name="CostAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListCA"]}
          updateOtherField={[
            {
              key: "CostAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "CostAccDesc",
      field: (
        <DescInForm
          name="CostAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },

    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Accu Acc.")}`}
          name="AccuAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListAA"]}
          updateOtherField={[
            {
              key: "AccuAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "AccuAccDesc",
      field: (
        <DescInForm
          name="AccuAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Depre Acc.")}`}
          name="DepreAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListDA"]}
          updateOtherField={[
            {
              key: "DepreAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          // rule={{
          // 	required: {
          // 		value: true,
          // 		message: "* Required",
          // 	},
          // }}
        />
      ),
    },
    {
      size: 2,
      name: "DepreAccDesc",
      field: (
        <DescInForm
          name="DepreAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  if (addMode) {
    const templateId = localStorage.getItem("templateId") != null ? parseInt(localStorage.getItem("templateId")) : 0;
    return (
      <Create
        {...props}
        formFields={formFieldsEdit}
        templateId={templateId ?? 0}
        formFieldsRegister={formFieldsRegister}
        formFieldsAccount={formFieldsAccount}
        vendorLookup={lookupList["vendorList"]}
        useStyles={useStyles}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFields={formFieldsEdit}
        formFieldsRegister={formFieldsRegister}
        formFieldsRemainValue={formFieldsRemainValue}
        formFieldsAccount={formFieldsAccount}
        vendorLookup={lookupList["vendorList"]}
        useStyles={useStyles}
      />
    );
  }
};

const ViewMode = (props) => {
  return <Show {...props} useStyles={useStyles} />;
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
