/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { useMediaQuery, Paper, Grid, Tooltip } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import DialogPayment from "./DialogPayment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { GetWfStatus } from "utils/options";
import SnackbarUtils from "utils/SnackbarUtils";
import { showReportByName } from "pages/Report/services";
import { getInvoiceDetail, delInvoiceDetail } from "services/accountPayable";
import { getWorkflowHistory } from "services/workflow";
import DialogSendEmail from "components/DialogSendEmail";
import CustomToolbarSelect from "./CustomToolbarSelect";
import ReportBackDrop from "components/ReportBackDrop";
import DialogViewAPInvoice from "../../../components/DialogViewAPInvoiceDetail";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { settingAll, DateToString, ToNumber, NumberFormat } = useContext(GblContext);
  const { SettingClosePeriod, SettingAp } = settingAll;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [dataView, setDataView] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [dataTax, setDataTax] = useState();
  const [wfHisList, setWfHisList] = useState();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);

  const { basePath, id, permissions, hideMailBtn, wfActive, wfEnumStatus, wfSteps } = props;

  const CheckDisableEditBtn = () => {
    if (wfActive) {
      if (data?.InvhStatus === "Effective") {
        return true;
      }
      //check step
      if (data?.InvhStatus && wfEnumStatus) {
        if (data?.InvhStatus === "Step0") {
          return false;
        }
        const haveItem = wfSteps?.find((item) => item.StepNo === parseInt(wfEnumStatus[data?.InvhStatus]));
        if (typeof haveItem === "undefined") {
          return true;
        }
      }
    }
    if (data?.InvhStatus === "Void") {
      return true;
    }
    return false;
  };

  const CheckDisableDelBtn = () => data?.InvhStatus === "Void";

  const IsSettled = () => {
    const sumTotal = data.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.TotalPrice);
      return s;
    }, 0);

    const sumUnpaid = data.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.UnPaid);
      return s;
    }, 0);

    if (NumberFormat(sumUnpaid) !== NumberFormat(sumTotal)) {
      return true;
    }
    return false;
  };

  const CheckEditBtn = () => {
    const msg = translate("ra.apInvoice.settleWarnning");
    if (data.InvhDate < SettingClosePeriod.ClosePeriodAp) {
      //ปิด period ไปแล้ว
      SnackbarUtils.confirm(msg, function () {
        localStorage.setItem("IsSettled", true);
        redirect("edit", basePath, id);
      });
    } else {
      //ยังไม่ถูกตัดจ่าย
      if (IsSettled() === false) {
        if (data.InvhSource !== "" && data.InvhSource !== "A/P") {
          if (SettingAp.AllowEditPostingInvoice ?? false) {
            redirect("edit", basePath, id);
          } else {
            SnackbarUtils.confirm(msg, function () {
              localStorage.setItem("IsSettled", true);
              redirect("edit", basePath, id);
            });
          }
        } else {
          redirect("edit", basePath, id);
        }
      }
      //ตัดจ่ายไปแล้ว
      else {
        SnackbarUtils.confirm(msg, function () {
          localStorage.setItem("IsSettled", true);
          redirect("edit", basePath, id);
        });
      }
    }
  };

  const CheckDeleteBtn = () => {
    if (data.InvhDate < SettingClosePeriod.ClosePeriodAp) {
      //ปิด period ไปแล้ว
      SnackbarUtils.warning(
        translate("ra.closePeriod.warning", {
          name: "invoice",
          action: "void",
        })
      );
    } else {
      //ยังไม่ถูกตัดจ่าย
      if (IsSettled() === false) {
        if (data.InvhSource !== "" && data.InvhSource !== "A/P") {
          if (SettingAp.AllowDeletePostingInvoice) {
            DelOrVoid();
          } else {
            SnackbarUtils.error("Not allow void invoice");
          }
        } else {
          DelOrVoid();
        }
      }
      //ตัดจ่ายไปแล้ว
      else {
        SnackbarUtils.warning(translate("ra.apInvoice.delInvoiceSettled"));
      }
    }
  };

  const onCloseDialogEmail = (statusCode, UserMessage) => {
    setLoading(true);
    if (statusCode === undefined) {
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode === 0) {
      SnackbarUtils.success(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode !== 0 && statusCode !== undefined) {
      SnackbarUtils.error(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      disabled: CheckDisableEditBtn(),
      fnc: () => CheckEditBtn(),
    },
    {
      name: "Void",
      disabled: CheckDisableDelBtn(),
      fnc: () => CheckDeleteBtn(),
    },
    { name: "Copy", fnc: () => redirect("copy", basePath) },
    {
      name: "Print",
      fnc: async () => {
        props.showReportLoader();
        await showReportByName("AP_Invoice", [{ Name: "InvhSeq", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    { name: "Payment", fnc: () => setOpenPaymentDialog(true) },
    {
      name: "Send Email",
      fnc: () => setOpenEmailDialog(true),
      hide: hideMailBtn,
    },
  ];

  const fetchInvoiceById = useCallback(
    async (mounted) => {
      const response = await getInvoiceDetail(id);
      if (response) {
        setData(response);
        const dataRow = response?.Detail[0];
        const tempDataTax = {
          ...dataRow,
          DeptCode: dataRow?.DeptCode,
          DeptDesc: dataRow?.DeptDesc,
          DrAcc: dataRow?.InvdBTaxDr,
          DrAccDesc: dataRow?.InvdBTaxDrDesc,
          CrAcc: dataRow?.InvdBTaxCr1,
          CrAccDesc: dataRow?.InvdBTaxCr1Desc,
          NetBaseAmt: dataRow?.NetBaseAmt,
          TaxBaseAmt1: dataRow?.InvdTaxA1,
          TaxType1: dataRow?.InvdTaxT1,
          TaxRate1: dataRow?.InvdTaxR1,
          TaxOverwrite1: dataRow?.InvdT1Cr,
          TaxAcc1: dataRow?.InvdT1Dr,
          TaxAcc1Desc: dataRow?.InvdT1DrDesc,
          TaxBaseAmt2: dataRow?.InvdTaxA2,
          TaxType2: dataRow?.InvdTaxT2,
          TaxRate2: dataRow?.InvdTaxR2,
          TaxOverwrite2: dataRow?.InvdT2Cr,
          TaxAcc2: dataRow?.InvdT2Dr,
          TaxAcc2Desc: dataRow?.InvdT2DrDesc,
        };
        setDataTax(tempDataTax);
      }
      if (mounted) {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  const fetchWorkflowHistory = useCallback(
    async (wfCode) => {
      const { data } = await getWorkflowHistory(wfCode, id);
      if (data) {
        setWfHisList(data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchInvoiceById(mounted);
    fetchWorkflowHistory("AP_INVOICE");
    return function cleanup() {
      mounted = false;
    };
  }, [fetchInvoiceById]);

  const DelOrVoid = async () => {
    const msg = translate("ra.question.confirmVoid", { module: "A/P Invoice" });
    SnackbarUtils.voidConfirm(msg, async function (remark) {
      const { Code, InternalMessage, UserMessage } = await delInvoiceDetail(
        id,
        localStorage.getItem("UserName"),
        remark
      );
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          const dataRow = data.Detail[value];
          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDataView(dataRow);
                  setOpenDialogDetail(!openDialogDetail);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 80 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let deptDesc = tableMeta.rowData[2];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{deptDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "DeptDesc",
      label: "Department Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdBTaxDr",
      label: "Account #",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 100 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let accDesc = tableMeta.rowData[4];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{accDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "InvdBTaxDrDesc",
      label: "Account Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdDesc",
      label: "Comment",
      options: {
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "InvdQty",
      label: "Qty",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "qty");
        },
      },
    },
    {
      name: "InvdPrice",
      label: "Price/Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "unit");
        },
      },
    },
    {
      name: "NetAmt",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "InvdTaxC1",
      label: "TAX",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(value + tableMeta.rowData[10]);
        },
      },
    },
    {
      name: "InvdTaxC2",
      label: "Tax 2",
      options: {
        display: false,
      },
    },
    {
      name: "TotalPrice",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "UnPaid",
      label: "Unpaid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumNet = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTax1 = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTotal = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumUnpaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[12]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "NetAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumNet}
                      </TableCell>
                    );
                  case "InvdTaxC1":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax1}
                      </TableCell>
                    );
                  case "TotalPrice":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "UnPaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim(data.Detail[0].DimList.Dim);
      setOpenDim(true);
    } else {
      setDataDim(values);
      setOpenDim(true);
    }
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.InvhSeq);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div
      // className={clsx(classes.drawer, {
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AP.Invoice"])}
      />
      {data &&
        wfEnumStatus &&
        data.InvhStatus !== "Effective" &&
        data.InvhStatus !== "Void" &&
        data.InvhStatus !== "Step0" && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.InvhStatus])}
            wfCode={"AP_INVOICE"}
            fncSuccess={() => {
              fetchInvoiceById();
              fetchWorkflowHistory("AP_INVOICE");
            }}
          />
        )}
      <Paper className={classes.root}>
        <BoxHeader
          header={"Invoice"}
          showSearch={!isXSmall}
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Invoice No.")}`,
            update: SearchByNo,
            module: "AP_Invoice",
            keyCode: "InvhInvNo",
            keyDesc: "InvhDesc",
          }}
          data={data}
          source={data.InvhSource}
          wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)}
          status={
            GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)
              ? GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)
              : data.InvhStatus
          }
        />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
          <TextTopInGrid sizeSm={2} label="Seq" value={data.InvhSeq} />
          <TextTopInGrid sizeSm={2} label="Invoice No." value={data.InvhInvNo} />
          <TextTopInGrid sizeSm={4} label="Vendor" value={`${data.VnCode} ${data.VnName ? ` : ${data.VnName}` : ""}`} />
          <TextTopInGrid sizeSm={2} label="Currency" value={data.CurCode} />
          <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurRate, "currency")} />
          <TextTopInGrid sizeSm={2} label="Input Date" value={DateToString(data.InvhDate)} />
          <TextTopInGrid sizeSm={2} label="Invoice Date" value={DateToString(data.InvhInvDate)} />
          <TextTopInGrid sizeSm={2} label="Credit" value={data.InvhCredit} />
          <TextTopInGrid sizeSm={2} label="Due Date" value={DateToString(data.InvhDueDate)} />
          <TextTopInGrid sizeSm={4} label="Description" multiline={true} value={data.InvhDesc} />
          <TextTopInGrid
            sizeSm={2}
            label="Tax Inv No."
            value={data.InvhTInvNo}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />
          <TextTopInGrid
            sizeSm={2}
            label="Tax Inv Date"
            value={DateToString(data.InvhTInvDt)}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />
          <TextTopInGrid
            sizeSm={2}
            label="Tax Status"
            value={data.TaxStatus}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />

          <TextTopInGrid
            sizeSm={2}
            label="Tax Period"
            value={data.TaxPeriod}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />
        </Grid>
        <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
      </Paper>
      {data &&
        wfEnumStatus &&
        data.InvhStatus !== "Effective" &&
        data.InvhStatus !== "Void" &&
        data.InvhStatus !== "Step0" &&
        isXSmall && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data?.InvhStatus])}
            wfCode={"AP_INVOICE"}
            fncSuccess={() => {
              fetchInvoiceById();
              fetchWorkflowHistory("AP_INVOICE");
            }}
          />
        )}
      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        dataTax={dataTax}
        module={"AP_Invoice"}
        moduleId={id}
        wfHisList={wfHisList}
      />
      {openEmailDialog && (
        <DialogSendEmail
          title={" Send A/P Invoice To Email"}
          open={openEmailDialog}
          onClose={onCloseDialogEmail}
          data={data}
          module={"AP_Invoice"}
          keyId="InvhSeq"
        />
      )}

      {openDialogDetail && (
        <DialogViewAPInvoice
          open={openDialogDetail}
          onClose={() => setOpenDialogDetail(false)}
          data={dataView}
          CurRate={data.CurRate}
        />
      )}
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
      {openPaymentDialog && (
        <DialogPayment open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)} InvhSeq={data.InvhSeq} />
      )}
    </div>
  );
};

export default ReportBackDrop(Show);
