import axiosAuth from "../utils/request";

export async function getFinancialReport(param) {
  const { data, status } = await axiosAuth.post(`/api/financialreport/${param.ReportId}`, param);
  if (status !== 200) {
    return [];
  }
  return data;
}

export async function getFinancialReportList() {
  const { data, status } = await axiosAuth.get("/api/financialreport/reportlist");
  if (status !== 200) {
    return [];
  }
  return data;
}

export async function getFinancialSetting(id) {
  const { data, status } = await axiosAuth.get(`/api/financialreport/setting/${id}`);
  if (status !== 200) {
    return [];
  }
  return data;
}

export async function updateFinancialSetting(reportId, param) {
  const { data, status } = await axiosAuth.put(`/api/financialreport/setting/${reportId}`, param);
  if (status !== 200) {
    return [];
  }
  return data;
}
