import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import { GblContext } from "providers/formatter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import TextTopInGrid from "./TextTopInGrid";
import { useTranslate } from "react-admin";
import DimensionContent from "components/DimensionContent";

function DialogViewJVDetail(props) {
  const { data, open, onClose, title } = props;
  const dimList = data?.DimList.Dim || [];
  const { NumberFormat } = useContext(GblContext);
  const translate = useTranslate();

  const labelList = {
    Amount: translate("ra.field.Amount"),
    Base: translate("ra.field.Base"),
    "Dr.": translate("ra.field.Debit"),
    "Cr.": translate("ra.field.Credit"),
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {title + " Detail"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <TextTopInGrid
              sizeSm={6}
              label="Department"
              value={
                data.DeptCode || data.DeptCode !== ""
                  ? data.DeptDesc || data.DeptDesc !== ""
                    ? data.DeptCode + " : " + data.DeptDesc
                    : data.DeptCode
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={6}
              label="Account #"
              value={
                data.AccCode || data.AccCode !== ""
                  ? data.AccDesc || data.AccDesc !== ""
                    ? data.AccCode + " : " + data.AccDesc
                    : data.AccCode
                  : ""
              }
            />

            <TextTopInGrid sizeSm={12} label="Comment" value={data?.Description} />
            <TextTopInGrid sizeSm={6} label="Currency" value={data?.CurCode} />
            <TextTopInGrid
              sizeSm={6}
              label="Rate"
              value={NumberFormat(data?.CurRate)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${labelList["Amount"]} ${labelList["Dr."]}`}
                value={NumberFormat(data?.DrAmount)}
                variant="outlined"
                margin="dense"
                readOnly
                fullWidth
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${labelList["Amount"]} ${labelList["Cr."]}`}
                value={NumberFormat(data?.CrAmount)}
                variant="outlined"
                margin="dense"
                readOnly
                fullWidth
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${labelList["Base"]} ${labelList["Dr."]}`}
                value={NumberFormat(data?.DrBase)}
                variant="outlined"
                margin="dense"
                readOnly
                fullWidth
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${labelList["Base"]} ${labelList["Cr."]}`}
                value={NumberFormat(data?.CrBase)}
                variant="outlined"
                margin="dense"
                readOnly
                fullWidth
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </Grid>

            <Grid xs={12} style={{ marginBottom: 7 }}>
              <Typography variant="h6" style={{ marginLeft: 7 }}>
                {translate("ra.module.Dimension")}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <DimensionContent data={dimList} />
            </Grid>
          </Grid>
        </DialogContent>
        <br />
      </Dialog>
    </>
  );
}

export default DialogViewJVDetail;
