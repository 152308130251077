/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Switch,
  FormControlLabel,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  MenuItem,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonFooter from "components/ButtonFooter";
import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import NumberFormatInput from "components/NumberFormatInput";
import ConfigFileInI from "../Dialog/ConfigFileIni";
import { getTemplateInfPost, updateSettingInfPost } from "services/interface";
import { getDepartmentList, getAccountCodeList } from "services/setting";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";
import SnackbarUtils from "utils/SnackbarUtils";
import gbl from "utils/formatter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  box: {
    border: "1px solid #a7a7a7",
    borderRadius: 8,
    marginBottom: 30,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Inventory = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
  });
  const { code, editMode, cancelFnc, data, currentModule } = props;
  const [infDetail, setInfDetail] = useState();
  const [dialog, setDialog] = useState({
    configFileGL: false,
    configFileAP: false,
  });

  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList();
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);

  React.useEffect(async () => {
    if (data && !infDetail) {
      setInfDetail(data);
    }
    if (editMode) {
      fetchAccLookup();
      fetchDeptLookup();
    }
  }, []);

  const SaveFnc = async () => {
    let param = {
      Name: infDetail.Name,
      PostType: infDetail.PostType ?? "File",
      DocType: infDetail.DocType,
      Module: currentModule,
      IsActive: infDetail.IsActive,
    };

    if (currentModule === "GL") {
      param.Configuration = {
        Inventory: infDetail.Configuration?.Inventory,
      };
    }
    if (currentModule === "AP") {
      param.Configuration = infDetail.Configuration;
    }

    if (!infDetail.Configuration) {
      param.Configuration = {};
    }

    const r = await updateSettingInfPost(code, currentModule, param);
    if (r?.Code >= 0) {
      SnackbarUtils.success(r.UserMessage, function () {
        cancelFnc("", "update");
      });
    } else {
      SnackbarUtils.warning(r.UserMessage);
    }
  };

  const SwitchActive = async (newValue) => {
    var tmpCfg;
    if (infDetail.Configuration === null && newValue) {
      tmpCfg = await getTemplateInfPost(infDetail.Module, infDetail.InterfaceType);

      if (tmpCfg.length > 0) {
        if (tmpCfg[0].InterfaceName === "Custom") {
          tmpCfg = tmpCfg[0];
        }
      }
    }
    setInfDetail((state) => ({
      ...state,
      DocType: tmpCfg?.DocType ?? state?.DocType ?? currentModule === "GL" ? "Inventory" : "RECEIVING",
      Configuration: tmpCfg?.Template ?? state?.Configuration,
      IsActive: newValue,
    }));
  };

  if (infDetail) {
    var iniCfgInventory, iniCfgAP;

    if (currentModule === "GL") {
      iniCfgInventory = gbl.Base64DecodeUnicode(infDetail.Configuration?.Inventory?.ConfigBase64);
    } else if (currentModule === "AP") {
      iniCfgAP = gbl.Base64DecodeUnicode(
        infDetail.Configuration?.ConfigBase64 || infDetail.Configuration?.ConfigFileBase64
      );
    }

    const filterOptions = (options, { inputValue }, optKey, optDesc) => {
      return matchSorter(options, inputValue, {
        keys: [`${optKey}`, `${optDesc}`],
      });
    };

    return (
      <div className={classes.box}>
        <ListItem>
          <ListItemText primary={"Use this interface"} />
          <FormControlLabel
            value={infDetail.IsActive}
            control={
              <Switch
                checked={infDetail.IsActive}
                onChange={(e, newValue) => SwitchActive(newValue)}
                disabled={!editMode}
              />
            }
            label={infDetail.IsActive ? translate("ra.field.Active") : translate("ra.field.In-Active")}
            labelPlacement="start"
            color="primary"
          />
        </ListItem>
        <ListItem button>
          <ListItemText primary={"InterfaceType"} />
          {infDetail["InterfaceType"]}
        </ListItem>
        <ListItem>
          <ListItemText primary={"Name"} />
          <div style={{ width: 280, display: "inline-flex" }}>
            <TextField
              key="Name"
              name="Name"
              variant="outlined"
              margin="dense"
              placeholder="Name"
              value={infDetail["Name"]}
              style={{ width: 280, display: "inline-flex" }}
              onChange={(e) => {
                setInfDetail((state) => ({
                  ...state,
                  Name: e.target.value,
                }));
              }}
              disabled={!editMode}
            />
          </div>
        </ListItem>
        <ListItem button>
          <ListItemText primary={"DocType"} />
          <div style={{ width: 280, display: "inline-flex" }}>
            <TextField
              key="DocType"
              name="DocType"
              variant="outlined"
              margin="dense"
              placeholder="DocType"
              value={infDetail["DocType"]}
              style={{ width: 280, display: "inline-flex" }}
              onChange={(e) => {
                setInfDetail((state) => ({
                  ...state,
                  DocType: e.target.value,
                }));
              }}
              disabled={!editMode}
            />
          </div>
        </ListItem>
        <ListItem button>
          <ListItemText primary={"PostType"} />
          <Select
            labelId="select-postType"
            id="select-postType"
            variant="outlined"
            margin="dense"
            value={infDetail["PostType"]}
            defaultValue={"File"}
            onChange={(e) => {
              setInfDetail((state) => ({
                ...state,
                PostType: e.target.value,
              }));
            }}
            disabled={!editMode}
            placeholder="Select type"
          >
            {["File", "Json"].map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </ListItem>

        {/* GL */}
        {infDetail.Module === "GL" && infDetail.IsActive && (
          <>
            <ListItem
              button
              className={classes.nested}
              disabled={!editMode}
              onClick={() => {
                setDialog((state) => ({ ...state, configFileGL: true }));
              }}
            >
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary={"Config File"} />
              <LaunchIcon />
            </ListItem>

            {dialog[`configFileGL`] && (
              <ConfigFileInI
                open={dialog[`configFileGL`]}
                onClose={() => setDialog((state) => ({ ...state, configFileGL: false }))}
                data={iniCfgInventory}
                update={(newData) => {
                  if (newData) {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        Inventory: {
                          ConfigBase64: gbl.UnicodeToBase64(newData ?? ""),
                        },
                      },
                    }));
                  }
                }}
                filename={`Config${code.split(".")[0]}(GL).txt`}
              />
            )}

            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary={"Department"} />
              {lookupList && lookupList["departmentList"] && (
                <Autocomplete
                  options={lookupList["departmentList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.Inventory?.DeptCode}
                  value={infDetail.Configuration?.Inventory?.DeptCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        Inventory: {
                          ...state.Configuration.Inventory,
                          DeptCode: newItem.DeptCode,
                          DeptDesc: newItem.Description,
                        },
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.DeptCode : option;
                  }}
                  getOptionSelected={(option, value) => option.DeptCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.DeptCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Department"
                      margin="dense"
                      placeholder="Department"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "DeptCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="Account Code" />
              {lookupList["accountCodeList"] && (
                <Autocomplete
                  options={lookupList["accountCodeList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.Inventory?.AccCode}
                  value={infDetail.Configuration?.Inventory?.AccCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        Inventory: {
                          ...state.Configuration.Inventory,
                          AccCode: newItem.AccCode,
                          AccDesc: newItem.Description,
                        },
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.AccCode : option;
                  }}
                  getOptionSelected={(option, value) => option.AccCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.AccCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="AccountCode"
                      margin="dense"
                      placeholder="AccountCode"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "AccCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
          </>
        )}

        {/* AP */}
        {infDetail.Module === "AP" && infDetail.IsActive && (
          <>
            <ListItem
              button
              className={classes.nested}
              disabled={!editMode}
              onClick={() => {
                setDialog((state) => ({ ...state, configFileAP: true }));
              }}
            >
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary={"Config File"} />
              <LaunchIcon />
            </ListItem>

            {dialog[`configFileAP`] && (
              <ConfigFileInI
                open={dialog[`configFileAP`]}
                onClose={() => setDialog((state) => ({ ...state, configFileAP: false }))}
                data={iniCfgAP}
                update={(newData) => {
                  if (newData) {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        ...state.Configuration,
                        ConfigBase64: gbl.UnicodeToBase64(newData ?? ""),
                      },
                    }));
                  }
                }}
                filename={`Config${code.split(".")[0]}(AR).txt`}
              />
            )}

            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary={"Department"} />
              {lookupList && lookupList["departmentList"] && (
                <Autocomplete
                  options={lookupList["departmentList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.DeptCode}
                  value={infDetail.Configuration?.DeptCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        ...state.Configuration,
                        DeptCode: newItem.DeptCode,
                        DeptDesc: newItem.Description,
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.DeptCode : option;
                  }}
                  getOptionSelected={(option, value) => option.DeptCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.DeptCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Department"
                      margin="dense"
                      placeholder="Department"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "DeptCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="Dr Account Code" />
              {lookupList && lookupList["accountCodeList"] && (
                <Autocomplete
                  options={lookupList["accountCodeList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.DrAccCode}
                  value={infDetail.Configuration?.DrAccCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        ...state.Configuration,
                        DrAccCode: newItem.AccCode,
                        DrAccDesc: newItem.Description,
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.AccCode : option;
                  }}
                  getOptionSelected={(option, value) => option.AccCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.AccCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="AccountCode"
                      margin="dense"
                      placeholder="AccountCode"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "AccCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="Cr Account Code" />
              {lookupList["accountCodeList"] && (
                <Autocomplete
                  options={lookupList["accountCodeList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.CrAccCode}
                  value={infDetail.Configuration?.CrAccCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        ...state.Configuration,
                        CrAccCode: newItem.AccCode,
                        CrAccDesc: newItem.Description,
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.AccCode : option;
                  }}
                  getOptionSelected={(option, value) => option.AccCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.AccCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="AccountCode"
                      margin="dense"
                      placeholder="AccountCode"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "AccCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="Tax Account Code" />
              {lookupList["accountCodeList"] && (
                <Autocomplete
                  options={lookupList["accountCodeList"]}
                  disableClearable
                  disableListWrap
                  defaultValue={infDetail.Configuration?.TaxAccCode}
                  value={infDetail.Configuration?.TaxAccCode}
                  onChange={(e, newItem) => {
                    setInfDetail((state) => ({
                      ...state,
                      Configuration: {
                        ...state.Configuration,
                        TaxAccCode: newItem.AccCode,
                        TaxAccDesc: newItem.Description,
                      },
                    }));
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  classes={{
                    option: classes.option,
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.AccCode : option;
                  }}
                  getOptionSelected={(option, value) => option.AccCode === value}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.AccCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);

                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{ width: 210, display: "inline-flex" }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="AccountCode"
                      margin="dense"
                      placeholder="AccountCode"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "AccCode", "Description")
                  }
                  disabled={!editMode}
                />
              )}
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="TaxType" />
              <Select
                labelId="select-taxType"
                id="select-taxType"
                variant="outlined"
                margin="dense"
                value={infDetail.Configuration?.TaxType}
                defaultValue={null}
                onChange={(e) => {
                  setInfDetail((state) => ({
                    ...state,
                    Configuration: {
                      ...state.Configuration,
                      TaxType: e.target.value,
                    },
                  }));
                }}
                disabled={!editMode}
                placeholder="Select type"
              >
                {["None", "Include", "Add"].map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
            <ListItem className={classes.nested}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText primary="Tax Rate" />
              <TextField
                variant="outlined"
                margin="dense"
                label="TaxRate"
                value={infDetail.Configuration?.TaxRate}
                style={{ width: 210, display: "inline-flex" }}
                onChange={(e) => {
                  setInfDetail((state) => ({
                    ...state,
                    Configuration: {
                      ...state.Configuration,
                      TaxRate: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  inputComponent: NumberFormatInput,
                }}
                inputProps={{
                  style: { textAlign: "right" },
                  maxLength: 18,
                  decimal: 2,
                }}
                disabled={!editMode}
              />
            </ListItem>
          </>
        )}

        {editMode && (
          <div
            style={{
              marginTop: 12,
              marginRight: 12,
              marginBottom: 12,
              display: "flex",
              justifyContent: " space-around",
            }}
          >
            <ButtonFooter noBorder SaveFnc={SaveFnc} CancelFnc={cancelFnc} />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Inventory;
