import { useEffect, useCallback, useState, useRef } from "react";
import _ from "lodash";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Loading } from "react-admin";
import { TreeView, TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { getFinancialSetting, updateFinancialSetting } from "services/financialdata";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MultiSelectListBox from "react-multiselect-listbox";
import { useLocale, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarUtils from "utils/SnackbarUtils";
import { getAccountCodeSearchList } from "services/setting";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1),
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    marginInline: 10,
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledTreeItem = withStyles((theme) => ({
  root: {
    "&:hover > $content $label": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
    },
    "&$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content , &$selected > $content $label": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
    },
  },
  content: {
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
  },
  expanded: {},
  selected: {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
  },
  label: {
    padding: 8,
  },
}))((props) => <TreeItem {...props} />);

const SettingFS = ({ lookupData }) => {
  const [isLoading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const [settingTree, setSettingTree] = useState();
  const [reportDetail, setReportDetail] = useState("");
  const [accCodeList, setAccCodeList] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedReport, setSelectedReport] = useState("PL");
  const [reportId, setReportId] = useState();
  const [usedAccCode, setUsedAccCode] = useState([]);
  const [originalAcc, setOriginalAcc] = useState([]);

  const fetchData = useCallback(
    async (type) => {
      setLoading(true);
      const getIdReport = lookupData.find((i) => i.Code === type)?.Id;
      setReportId(getIdReport);

      function extractAccountsFromNode(node) {
        let accounts = [];

        if (node.Accounts && node.Accounts.length > 0) {
          accounts = accounts.concat(node.Accounts);
        }

        if (node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            accounts = accounts.concat(extractAccountsFromNode(child));
          });
        }

        return accounts;
      }

      function extractAccounts(dataArray) {
        let allAccounts = [];

        dataArray.forEach((rootNode) => {
          allAccounts = allAccounts.concat(extractAccountsFromNode(rootNode));
        });

        return allAccounts;
      }

      try {
        // eslint-disable-next-line default-case
        switch (type) {
          case "PL":
            const plSetting = await getFinancialSetting(getIdReport);
            const accListPL = await getAccountCodeSearchList({
              WhereGroupList: [
                {
                  AndOr: "And",
                  ConditionList: [
                    {
                      AndOr: "And",
                      Field: "AccType",
                      Operator: "=",
                      Value: "S",
                    },
                    {
                      AndOr: "Or",
                      Field: "AccType",
                      Operator: "=",
                      Value: "I",
                    },
                  ],
                },
              ],
            });

            // Extract all accounts from the entire data array
            const usedAllPLAccounts = extractAccounts(plSetting);
            //const unUsedAcc1 = accListPL.Data.filter((i) => !usedAllPLAccounts.includes(i.AccCode));
            const newAccLookUp1 = accListPL?.Data.map((el, idx) => {
              return Object.assign(
                {},
                {
                  id: idx,
                  value: el.AccCode,
                  label: locale === "en-US" ? `${el.AccCode} ${el.Description}` : `${el.AccCode} ${el.Description2}`,
                }
              );
            });
            let original1 = _.cloneDeep(newAccLookUp1);
            setOriginalAcc(original1);
            setUsedAccCode(usedAllPLAccounts);
            setAccCodeList(newAccLookUp1);
            setSettingTree(plSetting);
            setLoading(false);
            break;
          case "BS":
            const bsSetting = await getFinancialSetting(getIdReport);
            const accListBS = await getAccountCodeSearchList({
              WhereGroupList: [
                {
                  AndOr: "And",
                  ConditionList: [
                    {
                      AndOr: "And",
                      Field: "AccType",
                      Operator: "=",
                      Value: "S",
                    },
                    {
                      AndOr: "Or",
                      Field: "AccType",
                      Operator: "=",
                      Value: "B",
                    },
                  ],
                },
              ],
            });
            // Extract all accounts from the entire data array
            const usedAllBSAccounts = extractAccounts(bsSetting);
            //const unUsedAcc2 = accListBS.Data.filter((i) => !usedAllBSAccounts.includes(i.AccCode));
            const newAccLookUp2 = accListBS?.Data.map((el, idx) => {
              return Object.assign(
                {},
                {
                  id: idx,
                  value: el.AccCode,
                  label: locale === "en-US" ? `${el.AccCode} ${el.Description}` : `${el.AccCode} ${el.Description2}`,
                }
              );
            });
            let original2 = _.cloneDeep(newAccLookUp2);
            setOriginalAcc(original2);
            setUsedAccCode(usedAllBSAccounts);
            setAccCodeList(newAccLookUp2);
            setSettingTree(bsSetting);
            setLoading(false);
            break;
          default:
            setLoading(false);
            break;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [locale, lookupData]
  );

  useEffect(() => {
    if (settingTree) {
      const allNodeIds = getAllNodeIds(settingTree);
      setExpanded(allNodeIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingTree]);

  let shouldGet = useRef(true);
  useEffect(() => {
    if (shouldGet.current) {
      shouldGet.current = false;
      fetchData(selectedReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllNodeIds = (data) => {
    let ids = [];
    data.forEach((item) => {
      ids.push(item.Name); // Add category node ID
      if (item.children) {
        ids = [...ids, ...getAllNodeIds(item.children)]; // Recursively add children node IDs
      }
    });
    return ids;
  };

  const handleChangeData = (event) => {
    setSelectedReport(event.target.value);
    setReportDetail("");
    fetchData(event.target.value);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleItemClick = (value) => {
    if (value?.Accounts !== undefined) {
      let filterAcc = usedAccCode.filter((i) => !value.Accounts.includes(i));
      let newAccList = originalAcc.filter((i) => !filterAcc.includes(i.value));
      setAccCodeList(newAccList);
      setReportDetail(value);
    } else {
      setReportDetail("");
    }
  };

  const handleChangeAcc = (selectedItems) => {
    setReportDetail({ ...reportDetail, Accounts: selectedItems });
  };

  const submit = async (formData) => {
    setBtnLoading(true);
    const { Code, InternalMessage, UserMessage } = await updateFinancialSetting(reportId, formData);
    if (Code === 0) {
      fetchData(selectedReport);
      SnackbarUtils.success(UserMessage);
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const treeCompo = () => {
    if (!settingTree) {
      return null;
    }
    const format = settingTree.map((item, index) => (
      <Grid item xs={12} key={index} style={{ padding: 0, margin: 0, marginInline: 10 }}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          <StyledTreeItem
            nodeId={item.Name}
            label={<span style={{ fontWeight: 900 }}>{item.Name}</span>}
            expanded={expanded}
            selected={selected}
            onClick={() => (item.NeedAcc ? handleItemClick(item) : null)}
          >
            {item.children &&
              item.children.map((subItem, subIndex) => (
                <StyledTreeItem
                  key={subIndex}
                  classes={classes.root}
                  nodeId={subItem.Name}
                  label={
                    subItem.children ? (
                      <strong style={{ paddingLeft: 14 }}>{subItem.Name}</strong>
                    ) : (
                      <span style={{ paddingLeft: 14 }}>{subItem.Name}</span>
                    )
                  }
                  onClick={() => (subItem.children ? null : handleItemClick(subItem))}
                >
                  {subItem.children &&
                    subItem.children.map((nestedItem, nestedIndex) => (
                      <StyledTreeItem
                        key={nestedIndex}
                        classes={classes.root}
                        nodeId={nestedItem.Name}
                        label={
                          nestedItem.children ? (
                            <strong style={{ paddingLeft: 24 }}>{nestedItem.Name}</strong>
                          ) : (
                            <span style={{ paddingLeft: 24 }}>{nestedItem.Name}</span>
                          )
                        }
                        onClick={() => (nestedItem.children ? null : handleItemClick(nestedItem))}
                      >
                        {nestedItem.children &&
                          nestedItem.children.map((nested4Item, nested4Index) => (
                            <StyledTreeItem
                              classes={classes.root}
                              key={nested4Index}
                              nodeId={nested4Item.Name}
                              label={<span style={{ paddingLeft: 40 }}>{nested4Item.Name}</span>}
                              onClick={() => handleItemClick(nested4Item)}
                            />
                          ))}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
              ))}
          </StyledTreeItem>
        </TreeView>
      </Grid>
    ));
    return <>{format}</>;
  };

  if (isLoading) return <Loading />;

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button disabled={disabled} variant="contained" color="primary" onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" style={{ marginBottom: 10 }}>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.title}>
            {translate("ra.module.Setting")}
          </Typography>
        </Grid>
        <Grid>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="label">Data</InputLabel>
            <Select
              labelId="label"
              variant="outlined"
              margin="dense"
              label="Data"
              value={selectedReport}
              onChange={handleChangeData}
              style={{ width: 194 }}
            >
              {lookupData &&
                lookupData.map((item) => (
                  <MenuItem key={item.Code} value={item.Code}>
                    {item.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6}>
          <Paper style={{ marginRight: 50, padding: 10 }}>
            <Box p={1} style={{ backgroundColor: "#E4E6ED", borderRadius: "5px", padding: 10 }}>
              <Typography variant="h5">Description</Typography>
            </Box>

            <Box height={"auto"}>
              <Grid container spacing={3} style={{ paddingInline: 8, marginTop: 10 }}>
                {treeCompo()}
              </Grid>
              <br />
            </Box>
          </Paper>
        </Grid>

        {reportDetail !== "" && (
          <Grid item xs={6}>
            <Paper style={{ padding: 10 }}>
              <Box p={1} style={{ backgroundColor: "#E4E6ED", borderRadius: "5px", padding: 10 }}>
                <Typography variant="h5">Detail</Typography>
              </Box>

              <Box>
                <br />
                <Box style={{ padding: 5 }}>
                  <Typography variant="h5" color="initial">
                    {reportDetail.Name}
                  </Typography>
                  <br />
                  <MultiSelectListBox
                    className={"multi-select"}
                    overrideStrings={{
                      search: "Search...",
                      selectAll: "Select All",
                      removeAll: "Remove All",
                      selectedInfo: "Items selected",
                    }}
                    sortable={true}
                    options={accCodeList}
                    textField="label"
                    valueField="value"
                    value={reportDetail.Accounts}
                    rowHeight={30}
                    onSelect={({ item, sortedList }) => {
                      handleChangeAcc(sortedList.map((i) => i.value));
                    }}
                    onRemove={({ item }) => {
                      handleChangeAcc([...reportDetail.Accounts.filter((i) => i !== item.value)]);
                    }}
                    onSelectAll={(selectedItems) => {
                      const selected = [...reportDetail.Accounts, ...selectedItems.map((item) => item.value)];
                      handleChangeAcc(selected);
                    }}
                    onRemoveAll={() => handleChangeAcc([])}
                    onSort={({ sortedList }) => handleChangeAcc([...sortedList.map((i) => i.value)])}
                    onSearch={({ items, textField, query }) =>
                      items.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()))
                    }
                  />
                  <br />
                  <Box display="flex" alignItems="left">
                    <LoadingButton
                      text="SAVE"
                      disabled={isBtnLoading}
                      fnc={() => submit(reportDetail, reportDetail.Accounts)}
                    />

                    <Button variant="outlined" className={classes.textCancel} onClick={() => setReportDetail("")}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
                <br />
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
      {/* <pre>{process.env.NODE_ENV === "development" ? JSON.stringify({ info }, 0, 2) : ""}</pre> */}
    </>
  );
};

export default SettingFS;
