import axiosAuth from "utils/request";

export async function checkIsUsedHq(tenant) {
  const { data } = await axiosAuth.get(`/api/isUsedHeadquarterFnc/${tenant}`);
  return data;
}

export async function getHQData() {
  const { data } = await axiosAuth.get("/api/Headquarter");
  return data;
}
